<script lang="ts">
  import dayjs from 'dayjs/esm/index.js';
  import { dateString } from 'shared/src/utils/date.utils';
  import { createEventDispatcher, onMount } from 'svelte';

  const format = 'YYYY-MM-DD';
  export let value: Date | null | undefined = undefined;
  type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  export let defaultTime: `${0 | 1 | 2 | ''}${Digit}:${Digit}${Digit}` = '23:59';
  export let id: string | undefined = undefined;
  export let min: Date | undefined = undefined;
  export let max: Date | undefined = undefined;
  export let required = false;
  export let isValid: boolean = true;

  const hardMin = '2013-01-01';
  const hardMax = '2100-01-01';

  let clazz: string = '';
  export { clazz as class };

  interface $$Events {
    change: CustomEvent<Date | null | undefined>;
    blur: CustomEvent<Date | null | undefined>;
  }

  const dispatch = createEventDispatcher<$$Events>();

  let internalValue: string;

  const dateInputStringToDateObject = (dateString: string) => {
    const [defaultHours, defaultMinutes] = defaultTime.split(':');

    const referenceDate = value || new Date();
    const newDate = dayjs(dateString, format).toDate();
    newDate.setHours(value ? referenceDate.getHours() : Number(defaultHours));
    newDate.setMinutes(value ? referenceDate.getMinutes() : Number(defaultMinutes));
    newDate.setSeconds(value ? referenceDate.getSeconds() : 0);
    newDate.setMilliseconds(value ? referenceDate.getMilliseconds() : 0);
    return newDate;
  };

  // When an external value comes into the component
  const onInput = (x: Date | null | undefined) => {
    const wasInvalid = dateInput ? !dateInput.checkValidity() : false;
    // console.log('onInput');
    if (!x) internalValue = '';
    else internalValue = dayjs(x).format(format);

    // If it was invalid and this makes it valid, remove any alert:
    validateDate('input');
    const isNewlyValid = dateInput ? dateInput.checkValidity() : true;
    // console.log({ wasInvalid, isNewlyValid });
    if (wasInvalid && isNewlyValid) {
      validateDate('blur');
    }
  };

  // When the value of the <input type="date" /> changes:
  const onOutput = (dateInputValueString: string) => {
    // console.log('onOutput');

    if (!dateInputValueString) {
      value = undefined;
      return;
    }

    const newDate = dateInputStringToDateObject(dateInputValueString);
    // console.log({ newDate });

    // If it's not valid, don't update the external value!
    const inputIsValid = dateInput ? dateInput.checkValidity() : false;
    // console.log({ inputIsValid });
    if (!inputIsValid) {
      ('input invalid, returning.');
      return;
    }

    value = newDate;
  };

  $: onInput(value);
  $: onOutput(internalValue);

  let minString = min ? dayjs(min).format('YYYY-MM-DD') : hardMin;
  let maxString = max ? dayjs(max).format('YYYY-MM-DD') : hardMax;

  let dateInput: HTMLInputElement;
  let validationErrorMessage = '';

  onMount(validateDate);

  function validateDate(method: 'input' | 'blur' = 'input') {
    // console.log('validateDate');
    if (typeof window !== 'undefined' && dateInput) {
      // If we have no span, it's valid:

      minString = min ? dayjs(min).format('YYYY-MM-DD') : hardMin;
      maxString = max ? dayjs(max).format('YYYY-MM-DD') : hardMax;

      const newDate = dateInputStringToDateObject(internalValue);
      const tooEarly = minString > dayjs(newDate).format('YYYY-MM-DD');
      const tooLate = maxString < dayjs(newDate).format('YYYY-MM-DD');

      if (!tooEarly && !tooLate) {
        validationErrorMessage = '';
      } else {
        // If it's not set, it's required, and we're not pristine:
        if (!internalValue && !required) {
          validationErrorMessage = '';
        } else if (!internalValue && required && method === 'blur') {
          validationErrorMessage = 'A date is required.';
        } else if (min && max) {
          validationErrorMessage = `Must be between ${dateString(min)} and ${dateString(max)}.`;
        } else if (min) {
          validationErrorMessage = `Must be ${dateString(min)} or later.`;
        } else if (max) {
          validationErrorMessage = `Must be before ${dateString(max)}.`;
        } else {
          validationErrorMessage = `Must be between ${hardMin} and ${hardMax}`;
        }
      }
      dateInput.setCustomValidity(validationErrorMessage);
      isValid = !validationErrorMessage;
      if (method === 'blur') {
        dateInput.reportValidity();
      }
      // console.log({ isValid, validationErrorMessage });
    }
    return isValid;
  }
</script>

<input
  min={minString || hardMin}
  max={maxString || hardMax}
  bind:this={dateInput}
  {id}
  class={clazz}
  class:error={!isValid}
  type="date"
  bind:value={internalValue}
  on:change={() => validateDate('input')}
  on:blur={() => {
    validateDate('blur');

    if (isValid) {
      // @ts-ignore
      dispatch('change', value);
    }

    // @ts-expect-error
    dispatch('blur', value);
  }}
/>
Valid: {isValid}

<style lang="postcss">
  .error {
    @apply border border-red outline-red;
  }
</style>
